<template>
  <b-row no-gutters>
    <b-col align="center">
      <img
          class="img-cover-radius"
          v-if="src"
          height="50"
          width="50"
          :src="src"
          alt
      />
      <img class="pointer"
           v-else
           height="40"
           width="40"
           src="@/assets/images/playground/ban.svg"
           alt
      />
    </b-col>
  </b-row>
</template>

<script>
  export default {
    data: () => ({
      src: null,
    }),
    created () {
      this.src = this.photo ? this.$filePath(this.photo.contentUrl) : null;
    },
    props: {
      photo: Object,
      default: () => {}
    }
  }
</script>
